export const CREATE_EVENT = "CREATE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const FETCH_EVENTS = "FETCH_EVENTS";
export const LISTEN_TO_EVENT_CHAT = "LISTEN_TO_EVENT_CHAT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_EVENT = "LISTEN_TO_SELECTED_EVENT";
export const CLEAR_SELECTED_EVENT = "CLEAR_SELECTED_EVENT";
export const CLEAR_EVENTS = "CLEAR_EVENTS";
export const SET_FILTER = "SET_FILTER";
export const START_DATE = "START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
